import { navigate, PageProps } from "gatsby"
import React from "react"
import Button from "../../../components/Button"
import FormeErrorLine from "../../../components/CereriNew/FormErrorLine"
import NewTabs, { I_NewTabItem } from "../../../components/CereriNew/NewTabs"
import { casnicSvg, comercialSvg } from "../../../components/Dash/svgs"
import Footer from "../../../components/Footer"
import FormInput from "../../../components/FormInput"
import useScrollRestorationToTop from "../../../components/Hooks/useScrollRestorationToTop"
import { InfoMsgType } from "../../../components/InfoMsg"
import SiteMenu from "../../../components/siteMenu"
import {
  ALFA_NUMERIC_ERROR,
  ALFA_NUMERIC_REGEX,
  CNP_ERROR,
  CNP_REGEX,
  EMAIL_ERROR,
  EMAIL_REGEX,
  PHONENUMBER_ERROR,
  PHONENUMBER_REGEX,
} from "../../../utils/regex"
import { getUserInfo } from "../../../utils/services/keepAuth"

import NewCheckBox from "../../../components/CereriNew/CheckBox"
import CheckBoxAgreeToTerms from "../../../components/CereriNew/CheckBoxAgreeToTerms"
import NewTextArea from "../../../components/CereriNew/NewTextArea"
import PopupLayout from "../../../components/Popups/PopupLayout"
import {
  DataCerereDuplicatContract,
  postCerereDuplicatContract,
} from "../../../utils/api/duplicatContract"
import { Helmet } from "react-helmet"

const initialFormData: DataCerereDuplicatContract = {
  isCasnic: true,
  denumire: "",
  cuicnp: "",
  adresa: "",
  localitate: "",
  judet: "",
  email: "",
  telefon: "",
  codClient: "",
  nrContract: "",
  nrFactura: "",
  trimitePeAcelasiEmail: true,
  acordDate: false,
}

const cereriButtonText = "Trimite cererea"

const tipClientTabItems: I_NewTabItem[] = [
  {
    id: 1,
    title: "Casnic",
    icon: casnicSvg,
    iconClassName: "casnic",
  },
  {
    id: 2,
    title: "Comercial",
    icon: comercialSvg,
    iconClassName: "comercial",
  },
]

const errorMesageForDateContract =
  "Trebuie să introduceți măcar unul dintre cele trei câmpuri."

function getFormErrorMessageForStep() {
  return "Te rugăm să completezi toate câmpurile"
}

function isButtonEnabled(formData: DataCerereDuplicatContract) {
  return (
    ALFA_NUMERIC_REGEX(formData.denumire) &&
    (formData.isCasnic
      ? CNP_REGEX(formData.cuicnp)
      : ALFA_NUMERIC_REGEX(formData.cuicnp)) &&
    ALFA_NUMERIC_REGEX(formData.adresa) &&
    ALFA_NUMERIC_REGEX(formData.localitate) &&
    ALFA_NUMERIC_REGEX(formData.judet) &&
    EMAIL_REGEX(formData.email) &&
    PHONENUMBER_REGEX(formData.telefon) &&
    (ALFA_NUMERIC_REGEX(formData.codClient) ||
      ALFA_NUMERIC_REGEX(formData.nrContract) ||
      ALFA_NUMERIC_REGEX(formData.nrFactura)) &&
    formData.acordDate
  )
}

export default function CerereAvizAmplasament({ location }: PageProps) {
  const [infoMsg, setInfoMsg] = React.useState<InfoMsgType>({
    isLoading: false,
    // status: "error",
  })
  const [formData, setFormData] =
    React.useState<DataCerereDuplicatContract>(initialFormData)
  const [nrHover, setNrHover] = React.useState(0)

  const timeOutRefs = React.useRef<NodeJS.Timeout[]>([])

  React.useEffect(() => {
    // Page unmount cleanup function
    const localUserInfo = getUserInfo()
    if (localUserInfo != null) {
      setFormData(oldData => ({
        ...oldData,
        denumire: localUserInfo.denumire ?? "",
        cuicnp: localUserInfo.cuicnp ?? "",
        adresa: localUserInfo.adresaBase ?? "",
        email: localUserInfo.email ?? "",
        telefon: localUserInfo.telefon ?? "",
        codClient: localUserInfo.codClient ?? "",
      }))
    }
    return () => {
      timeOutRefs.current.forEach(clearTimeout)
    }
  }, [])

  useScrollRestorationToTop("body", location.pathname)

  const handleFormDataChange = (value: string, name: string) => {
    setFormData(oldFormData => ({
      ...oldFormData,
      [name]: value,
    }))
  }

  const handleSubmit = async () => {
    const root = document.getElementById("cerereAudienta")

    if (root != null)
      root.scrollTo({
        top: 0,
        left: 0,
        behavior: "instant",
      })
    setInfoMsg({
      isLoading: true,
    })

    try {
      const apiResponse = await postCerereDuplicatContract({
        ...formData,
        numeReprezentantLegal: formData.numeReprezentantLegal,
        accountId: getUserInfo()?._id ?? undefined,
      })
      if (apiResponse.type) {
        setInfoMsg({
          isLoading: true,
          status: "ok",
          msg: "Veți primi un email de confirmarea.  Te redirecționăm către pagina principală",
        })
        timeOutRefs.current.push(
          setTimeout(() => {
            navigate("/")
          }, 5000)
        )
        // setFormData(initialFormData)
      } else {
        setInfoMsg({
          isLoading: true,
          status: "error",
          msg: "Vă rugăm reîncercați. Acest pop-up va dispărea în câteva momente!",
        })
        timeOutRefs.current.push(
          setTimeout(() => {
            setInfoMsg({ isLoading: false })
          }, 3000)
        )
      }
    } catch (error) {
      console.warn(error)
      setInfoMsg({
        isLoading: true,
        status: "error",
        msg: "Vă rugăm reîncercați.Acest pop-up va dispărea în câteva momente!",
      })
      timeOutRefs.current.push(
        setTimeout(() => {
          setInfoMsg({ isLoading: false })
        }, 3000)
      )
    }
  }

  return (
    <div id="cerereAudienta" className="cererePage">
      <Helmet>
        <title>Cerere Duplicat Contract Furnizare - Gazmir</title>
        <meta
          name="description"
          content="Depune o Cerere pentru a obtine un Duplicat al Contractului de Furnizare. Solicita eliberarea unei copii a contractului tau de furnizare."
        />
        <meta
          name="keywords"
          content="Duplicat contract furnizare Gazmir,Copie contract furnizare gazmir,Cerere emitere copie contract gazmir,copie contract gazmir,Gazmir duplicat contract"
        />
      </Helmet>
      <SiteMenu location={location} showPaymentRedirectLoading={false} />

      <form id="formAudienta" className="newCereriContainer">
        <h1 className="title">Cerere emitere duplicat contract furnizare</h1>
        <div className="cerereStep1">
          <NewTabs
            containerClassName="tipClientContainer"
            title="Tip client"
            subTitle="Ești client casnic sau comercial?"
            items={tipClientTabItems}
            idTabSelected={formData.isCasnic ? 1 : 2}
            setIdTabSelected={(id: number) =>
              setFormData(oldData => ({ ...oldData, isCasnic: id === 1 }))
            }
          />
          <div className="formDate formDateIdentificare ncsShadow">
            <div className="formSectionTitle">
              {formData.isCasnic
                ? "Date personale titular (din C.I.)"
                : "Date de indetificare societate"}
            </div>
            <FormInput
              type="text"
              key={formData.isCasnic ? "CD" : "PD"}
              containerClassName="ncsInputContainer"
              label={
                formData.isCasnic ? "Nume și prenume" : "Denumire societate"
              }
              name="denumire"
              onChange={handleFormDataChange}
              value={formData.denumire}
              errorTest={ALFA_NUMERIC_REGEX}
              errorMessage={ALFA_NUMERIC_ERROR}
              showWithoutTouched={nrHover > 1}
            />
            <FormInput
              type="text"
              key={formData.isCasnic ? "CC" : "PC"}
              containerClassName="ncsInputContainer"
              label={
                formData.isCasnic
                  ? "CNP - Cod Numeric Personal"
                  : "Certificat unic de înregistrare (C.U.I.)"
              }
              name="cuicnp"
              onChange={handleFormDataChange}
              value={formData.cuicnp}
              errorTest={formData.isCasnic ? CNP_REGEX : ALFA_NUMERIC_REGEX}
              errorMessage={formData.isCasnic ? CNP_ERROR : ALFA_NUMERIC_ERROR}
              showWithoutTouched={nrHover > 1}
            />

            <FormInput
              type="text"
              key={formData.isCasnic ? "CA" : "PA"}
              containerClassName="ncsInputContainer"
              label={
                formData.isCasnic ? "Adresă Domiciliu" : "Adresă sediu social"
              }
              name="adresa"
              onChange={handleFormDataChange}
              value={formData.adresa}
              errorTest={ALFA_NUMERIC_REGEX}
              errorMessage={ALFA_NUMERIC_ERROR}
              showWithoutTouched={nrHover > 1}
            />
            <FormInput
              type="text"
              key={formData.isCasnic ? "CL" : "PL"}
              containerClassName="ncsInputContainer"
              label="Localitate"
              name="localitate"
              onChange={handleFormDataChange}
              value={formData.localitate}
              errorTest={ALFA_NUMERIC_REGEX}
              errorMessage={ALFA_NUMERIC_ERROR}
              showWithoutTouched={nrHover > 1}
            />
            <FormInput
              type="text"
              key={formData.isCasnic ? "CJ" : "PJ"}
              containerClassName="ncsInputContainer"
              label="Județ"
              name="judet"
              onChange={handleFormDataChange}
              value={formData.judet}
              errorTest={ALFA_NUMERIC_REGEX}
              errorMessage={ALFA_NUMERIC_ERROR}
              showWithoutTouched={nrHover > 1}
            />
            {!formData.isCasnic && (
              <FormInput
                type="text"
                key="PNR"
                containerClassName="ncsInputContainer"
                // containerStyle={cereriStyles.inputContainer}
                // style={cereriStyles.input}
                // errorStyle={cereriStyles.inputError}
                label="Numele reprezentantului legal"
                name="numeReprezentantLegal"
                onChange={handleFormDataChange}
                value={formData.numeReprezentantLegal}
                errorTest={ALFA_NUMERIC_REGEX}
                errorMessage={ALFA_NUMERIC_ERROR}
                showWithoutTouched={nrHover > 1}
              />
            )}
            <FormInput
              type="text"
              key={formData.isCasnic ? "CE" : "PE"}
              containerClassName="ncsInputContainer"
              label="Email"
              name="email"
              onChange={handleFormDataChange}
              value={formData.email}
              errorTest={EMAIL_REGEX}
              errorMessage={EMAIL_ERROR}
              showWithoutTouched={nrHover > 1}
            />
            <FormInput
              type="text"
              key={formData.isCasnic ? "CT" : "PT"}
              containerClassName="ncsInputContainer"
              label="Număr de telefon"
              name="telefon"
              onChange={handleFormDataChange}
              value={formData.telefon}
              errorTest={PHONENUMBER_REGEX}
              errorMessage={PHONENUMBER_ERROR}
              showWithoutTouched={nrHover > 1}
            />
          </div>
          <div className="formDate ncsShadow">
            <div className="formSectionTitle">Date contract</div>
            <div className="formSectionSubTitle">
              Trebuie să introduceți măcar unul dintre cele trei câmpuri.
            </div>

            <FormInput
              type="text"
              key="CC"
              containerClassName="ncsInputContainer"
              label="Cod client"
              name="codClient"
              onChange={handleFormDataChange}
              value={formData.codClient}
              errorTest={
                formData.codClient !== "" ? ALFA_NUMERIC_REGEX : undefined
              }
              errorMessage={ALFA_NUMERIC_ERROR}
              isRequired={false}
              showErrorText={formData.codClient !== ""}
            />
            <FormInput
              type="text"
              key="NRCTR"
              containerClassName="ncsInputContainer"
              label="Număr și dată contract"
              name="nrContract"
              onChange={handleFormDataChange}
              value={formData.nrContract}
              errorTest={
                formData.nrContract !== "" ? ALFA_NUMERIC_REGEX : undefined
              }
              errorMessage={ALFA_NUMERIC_ERROR}
              isRequired={false}
              showErrorText={formData.nrContract !== ""}
            />
            <FormInput
              type="text"
              key="NRFAC"
              containerClassName="ncsInputContainer"
              label="Numărul ultimei facturi emise"
              name="nrFactura"
              onChange={handleFormDataChange}
              value={formData.nrFactura}
              errorTest={
                formData.nrFactura !== "" ? ALFA_NUMERIC_REGEX : undefined
              }
              errorMessage={ALFA_NUMERIC_ERROR}
              isRequired={false}
              showErrorText={formData.nrFactura !== ""}
            />

            {!(
              ALFA_NUMERIC_REGEX(formData.nrContract) ||
              ALFA_NUMERIC_REGEX(formData.nrFactura) ||
              ALFA_NUMERIC_REGEX(formData.codClient)
            ) &&
              nrHover > 1 && (
                <div className="ncsErrorMesage">
                  {errorMesageForDateContract}
                </div>
              )}
          </div>

          <div className="formCheckBoxes">
            <NewCheckBox
              id="trimitePeAcelasiEmail"
              checked={formData.trimitePeAcelasiEmail}
              labelText="Doresc să primesc duplicatul solicitat la adresa de email completata anterior"
              onChange={() => {
                setFormData(oldData => ({
                  ...oldData,
                  trimitePeAcelasiEmail: !oldData.trimitePeAcelasiEmail,
                }))
              }}
              showError={nrHover > 1 && !formData.trimitePeAcelasiEmail}
            />
            <CheckBoxAgreeToTerms
              id="acordDate"
              checked={formData.acordDate}
              onChange={() =>
                setFormData(oldData => ({
                  ...oldData,
                  acordDate: !oldData.acordDate,
                }))
              }
              showError={nrHover > 1 && !formData.acordDate}
            />
          </div>
        </div>

        <FormeErrorLine
          status="error"
          errorMessage={getFormErrorMessageForStep()}
          doesFormHaveErrors={!isButtonEnabled(formData)}
          nrHover={nrHover}
          setNrHover={setNrHover}
        />
        {infoMsg.status !== "ok" && (
          <Button
            isLoading={infoMsg.isLoading}
            as={"button"}
            disabled={!isButtonEnabled(formData)}
            aspect="primary"
            className="ncsButton"
            label={cereriButtonText}
            onClick={handleSubmit}
          />
        )}
      </form>
      <PopupLayout
        querySelectorForBlurredBackground="#formAudienta"
        querySelectorForStopScrolling="#cerereAudienta"
        titleExtraClassName="popupExtraTitle"
        popupState={{
          showPopup: infoMsg.isLoading || infoMsg.status != null,
          type: "infoCerere",
          title:
            infoMsg.isLoading && infoMsg.status == null
              ? "Se trimite cererea"
              : infoMsg.status === "ok"
              ? "Cererea a fost trimisă cu succes"
              : "A apărut o eroare la transmiterea cererii",
          infoMsg: infoMsg,
        }}
      />
      <Footer />
    </div>
  )
}
