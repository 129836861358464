import { apiBase, ApiResponse, customPost } from "./utils"

const baseUrl = `${apiBase}/api/duplicatContract`

export type DataCerereDuplicatContract = {
  accountId?: string
  isCasnic: boolean
  denumire: string
  cuicnp: string
  adresa: string
  localitate: string
  judet: string
  numeReprezentantLegal?: string
  email: string
  telefon: string
  codClient: string
  nrContract: string
  nrFactura: string
  trimitePeAcelasiEmail: boolean
  acordDate: boolean
}

export function postCerereDuplicatContract(
  formData: DataCerereDuplicatContract
): Promise<ApiResponse<null>> {
  return customPost(baseUrl, formData) as Promise<ApiResponse<null>>
}
